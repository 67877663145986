import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  }, {
    path: '/conferencias',
    name: 'Conferencias',
    component: () => import('@/views/Conferencias.vue')
  }, {
    path: '/conocimiento/:page?',
    name: 'Conocimiento',
    component: () => import('@/views/Conocimiento.vue')
  }, {
    path: '/articulo/:id?/:urlfriendly?',
    name: 'ConocimientoSingle',
    component: () => import('@/views/ConocimientoSingle.vue')
  }, {
    path: '/contacto',
    name: 'Contacto',
    component: () => import('@/views/Contacto.vue')
  }, {
    path: '/diplomados',
    name: 'Diplomados',
    component: () => import('@/views/Diplomados.vue')
  }, {
    path: '/diplomado/:id?/:urlfriendly?',
    name: 'DiplomadoSingle',
    component: () => import('@/views/DiplomadoSingle.vue')
  }, {
    path: '/inscripcion/:id?/:urlfriendly?',
    name: 'Inscripcion',
    component: () => import('@/views/Inscripcion.vue')
  }, {
    path: '/inscrito',
    name: 'Inscrito',
    component: () => import('@/views/Inscrito.vue')
  }, {
    path: '/gracias',
    name: 'Gracias',
    component: () => import('@/views/Gracias.vue')
  }, {
    path: '/sobre',
    name: 'Sobre',
    component: () => import('@/views/Sobre.vue')
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
})

export default router
