<template>
  <footer
    v-if="classes"
    class="footer-2 bg-dark pt16 pb16 text-center"
  >
    <div class="col-sm-2">
      &nbsp;
    </div>
    <div class="col-sm-8 text-center">
      <span class="fade-1-4">&copy; 2021 Nebula Juno</span>
    </div>
    <div class="col-sm-2 text-right text-center-xs">
      <a
        class="underline"
        href=""
      >Acuerdo de privacidad</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      classes: true,
    };
  },
  methods: {
    _handleClasses() {
      this.classes = !this.$route.name === "Gracias";
    },
  },
  watch: {
    $route(a, b) {
      this._handleClasses();
    },
  },
};
</script>
<style lang="css">
.footer-bottom {
  position: relative;
  bottom: 0;
  width: 100%;
}
</style>