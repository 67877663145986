import "babel-polyfill";
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import VueSession from "vue-session";
Vue.use(VueSession, { persist: true });

import { DateTime } from "luxon";
Vue.prototype.$Datetime = DateTime;

import VuePageTransition from "vue-page-transition";
Vue.use(VuePageTransition);

import VueResource from "vue-resource";
Vue.use(VueResource);

import VueMask from "v-mask";
Vue.use(VueMask);

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

// const baseUrl = "http://localhost/laura/admin-api/";
const baseUrl = "https://bioemocioninteligente.com/api/";
Vue.prototype.$theUrl = 'https://bioemocioninteligente.com/#/';
Vue.prototype.$baseUrl = baseUrl;
Vue.prototype.$filesUrl = baseUrl + "uploads/";
Vue.http.options.root = baseUrl + 'web';
Vue.http.options.emulateJSON = true;

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
