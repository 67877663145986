<template>
  <div id="app">
    <Nav />
    <div class="main-container">
      <vue-page-transition name="fade">
        <router-view />
      </vue-page-transition>
      <Footer />
    </div>
  </div>
</template>
<script>
import Nav from "@/components/Nav.vue";
import Footer from "@/components/Footer.vue";
export default {
  nam: "App",
  components: { Nav, Footer },
};
</script>
<style lang="scss">
@import "styles/_app";
</style>
