<template>
  <div class="home">
    <section class="pt0 pb0">
      <div class="gradient home-gradient">
        <div class="gradient-lit">
          <p class="gradient-text">Primero, respira</p>
        </div>
      </div>
    </section>
    <section class="pb0">
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <div class="feature bg-secondary pt64 pb64 pt-xs-32 pb-xs-32 mb0">
              <p class="lead mb0 p32">
                Somos una consciencia que habita un cuerpo físico. Cuando
                enfermamos no hay un cuerpo que curar; hay una culpa por sanar,
                un recuerdo que perdonar, una historia que agradecer, una mente
                por limpia...recodifica tu mente<br class="hidden-sm" />
                “Creando una nueva consciencia inteligente”
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section
      class="pb0"
      data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-4">
            <router-link to="/diplomados" class="fade-on-hover">
              <img class="br2 mb24" alt="" src="@/assets/posgrados.jpg" />
            </router-link>
            <h4 class="uppercase">DIPLOMADO</h4>
            <p>
              Intégrate a nuestro Diplomado en Terapia Emocional y sé el
              Terapeuta que este tiempo evolutivo necesita. Tenemos esa opción
              que buscas para tu preparación profesional como Terapeuta
              Emocional.
            </p>
            <router-link
              class="btn btn-filled"
              to="/diplomados"
              v-text="'Ver más'"
            />
          </div>

          <div class="col-sm-4">
            <router-link to="/conferencias" class="fade-on-hover">
              <img class="br2 mb24" alt="Pic" src="@/assets/conferencias.jpg" />
            </router-link>
            <h4 class="uppercase">CONFERENCIAS</h4>
            <p>
              Sé parte de nuestro espacio educativo vanguardista y permanece en
              continuo aprendizaje profesional a través de nuestras exposiciones
              temáticas.
            </p>
            <router-link
              class="btn btn-filled"
              to="/conferencias"
              v-text="'Ver más'"
            />
          </div>

          <div class="col-sm-4">
            <router-link to="/contacto" class="fade-on-hover">
              <img class="br2 mb24" alt="Pic" src="@/assets/laura-cita.jpg" />
            </router-link>
            <h4 class="uppercase">AGENDA TU CITA</h4>
            <p>
              Contáctanos para esa atención terapéutica personalizada que
              necesitas, tenemos el acompañamiento ideal y eficaz para la
              resolución de tus conflictos emocionales.
            </p>
            <router-link
              class="btn btn-filled"
              to="/contacto"
              v-text="'¡Agéndala ahora!'"
            />
          </div>
        </div>
        <!--end of row-->
      </div>
      <!--end of container-->
    </section>
    <section
      class="pb0"
      data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
    >
      <conferencias-proximas />
      <div class="pt240 pb240 relative">
        <div class="background-image-holder">
          <img
            alt="Pic"
            class="background-image"
            src="@/assets/gradientes/BananaMania.jpg"
          />
        </div>
      </div>
    </section>
    <!--section
      class="pb0"
      data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
    >
      <div class="container">
        <div class="row">
          <div class="col-sm-12 text-center">
            <h2 class="uppercase mb48">¡EFICACIA CON RESULTADOS REALES!</h2>
            <h3 class="uppercase mb48">TESTIMONIOS</h3>
            <OCULTAR ESTE AL PONER LOS TESTIMONIOS a class="fade-half" href="index.html#">Contacto</a
          </div>
        </div>
      </div>
    </section>
    <section class="pb0 team top-layer">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 overflow-hidden p0">
            <div
              class="col-sm-6 mb30"
              data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
            >
              <img alt="Pic" src="@/assets/laura.jpg" />
              <div class="p32 pt32 bg-dark overflow-hidden">
                <h4 class="uppercase mb8">Testimonio 1</h4>
                <span class="inline-block mb80 mb-xs-80"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  dapibus, erat porttitor luctus iaculis, diam tortor cursus
                  nisl, sed consequat elit neque vitae mi. Sed a facilisis
                  mauris, sed egestas lorem. Aliquam vitae felis maximus, ornare
                  urna a, posuere nisl. Pellentesque odio purus, convallis in
                  ultricies non, vestibulum ac orci. Donec congue non dui sed
                  posuere. Nulla leo nulla, maximus vitae nulla eu, ultricies
                  rhoncus ipsum. Praesent a laoreet libero.
                </span>
              </div>
            </div>

            <div
              class="col-sm-6 mb30"
              data-sr="enter bottom move 20px over .5s and scale 0% and wait .3s"
            >
              <img alt="Pic" src="@/assets/marcos.jpg" />
              <div class="p32 pt32 bg-dark overflow-hidden">
                <h4 class="uppercase mb8">Testimonio 2</h4>
                <span class="inline-block mb80 mb-xs-80"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  dapibus, erat porttitor luctus iaculis, diam tortor cursus
                  nisl, sed consequat elit neque vitae mi. Sed a facilisis
                  mauris, sed egestas lorem. Aliquam vitae felis maximus, ornare
                  urna a, posuere nisl. Pellentesque odio purus, convallis in
                  ultricies non, vestibulum ac orci. Donec congue non dui sed
                  posuere. Nulla leo nulla, maximus vitae nulla eu, ultricies
                  rhoncus ipsum. Praesent a laoreet libero.
                </span>
              </div>
            </div>

            <div
              class="col-sm-6 mb30"
              data-sr="enter bottom move 20px over .5s and scale 0% and wait 0s"
            >
              <img alt="Pic" src="@/assets/laura.jpg" />
              <div class="p32 pt32 bg-dark overflow-hidden">
                <h4 class="uppercase mb8">Testimonio 3</h4>
                <span class="inline-block mb80 mb-xs-80"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  dapibus, erat porttitor luctus iaculis, diam tortor cursus
                  nisl, sed consequat elit neque vitae mi. Sed a facilisis
                  mauris, sed egestas lorem. Aliquam vitae felis maximus, ornare
                  urna a, posuere nisl. Pellentesque odio purus, convallis in
                  ultricies non, vestibulum ac orci. Donec congue non dui sed
                  posuere. Nulla leo nulla, maximus vitae nulla eu, ultricies
                  rhoncus ipsum. Praesent a laoreet libero.
                </span>
              </div>
            </div>

            <div
              class="col-sm-6 mb30"
              data-sr="enter bottom move 20px over .5s and scale 0% and wait .3s"
            >
              <img alt="Pic" src="@/assets/marcos.jpg" />
              <div class="p32 pt32 bg-dark overflow-hidden">
                <h4 class="uppercase mb8">Testimonio 4</h4>
                <span class="inline-block mb80 mb-xs-80"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis
                  dapibus, erat porttitor luctus iaculis, diam tortor cursus
                  nisl, sed consequat elit neque vitae mi. Sed a facilisis
                  mauris, sed egestas lorem. Aliquam vitae felis maximus, ornare
                  urna a, posuere nisl. Pellentesque odio purus, convallis in
                  ultricies non, vestibulum ac orci. Donec congue non dui sed
                  posuere. Nulla leo nulla, maximus vitae nulla eu, ultricies
                  rhoncus ipsum. Praesent a laoreet libero.
                </span>
              </div>
            </div>
          </div>

        </div>

      </div>

    </section-->
  </div>
</template>

<script>
import ConferenciasProximas from "@/components/conferencias/ConferenciasProximas.vue";
export default {
  name: "Home",
  components: {
    ConferenciasProximas,
  },
};
</script>
