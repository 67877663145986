<template>
  <div class="container pull-bottom-half">
    <div class="row">
      <div class="col-sm-12">
        <div class="pt64 pb24 bg-secondary">
          <h4 class="text-center uppercase mb64 mb-xs-40">
            Próximas conferencias
          </h4>
          <ul class="mb0">
            <li class="overflow-hidden text-center-sm mb40">
              <div class="col-md-10 col-md-offset-1 mb24">
                Lo que atraes a tu vida está en sincronia con tus pensamientos.
              </div>
            </li>
            <li
              class="overflow-hidden text-center-sm mb40"
              v-for="(cnf, idx) in conferencias"
              :key="idx"
            >
              <div class="col-md-3 col-md-offset-1 mb24">
                <span class="title mb16">{{ cnf.titulo_con }}</span>
                <span>
                  {{
                    $Datetime
                      .fromSQL(cnf.fecha_con + " " + cnf.hora_con)
                      .setLocale("es-Mx")
                      .toFormat("EEE, dd LLL")
                  }}
                  <br />
                  {{
                    $Datetime
                      .fromSQL(cnf.fecha_con + " " + cnf.hora_con)
                      .setLocale("es-Mx")
                      .toFormat("h:mm a")
                  }}
                </span>
              </div>
              <div class="col-md-7 mb24">
                <p class="mb0">
                  {{ cnf.desc_con }}
                </p>
              </div>
              <!-- <div class="col-md-3">
                <router-link
                  class="btn"
                  :to="'/conferencia/'+cnf.id"
                  v-text="'Ver más'"
                />
              </div> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "ConferenciasProximas",
  mixins: [forms],
  data() {
    return {
      conferencias: [],
    };
  },
  created() {
    this._getThings("conferencias?limit=2", "conferencias");
    //en un lugar
  },
};
</script>