export default {
    data() {
        return {
            cargando: false
        }
    },
    methods: {
        async _getThings(url = null, whatToGet = null, params = '') {
            if (url && whatToGet) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.get(
                        url + params,
                        this.$headers
                    );
                    if (status === 200 && whatToGet in body) {
                        this[whatToGet] = body[whatToGet];
                    } else {
                        this.$emit("httpError", res);
                    }
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err);
                }
            }
        },
        _validateAll(dataVariable = null) {
            //Se estandariza que solo lo que verificara estara en "errors" y debera tener el mismo object que que en su variable correspondiente
            if (dataVariable) {
                Object.keys(this.errors).forEach(e => {
                    this.errors[e] = this._hasError(this[dataVariable][e])
                });
                return this._validate(this.errors)
            } else {
                return false;
            }
        },
        _hasError(a, isDate = false) {
            return a ? null : 'Llena este campo';
        },
        _validate(arr) {
            return Object.values(arr).indexOf('Llena este campo') <= -1 ? true : false;
        },
        async _saveAll(ruta = null, data = null, whatShouldLookFor = null, functionHandleSuccess = null) {
            if (ruta && data && whatShouldLookFor) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.post(ruta, data, this.$headers)
                    this._handleResponse(status, whatShouldLookFor, body, functionHandleSuccess)
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err)
                }
            }
        },
        async _updateAll(ruta = null, data = null, whatShouldLookFor = null, functionHandleSuccess = null) {
            if (ruta && data && whatShouldLookFor) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.put(ruta, data, this.$headers)
                    this._handleResponse(status, whatShouldLookFor, body, functionHandleSuccess)
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err)
                }
            }
        },
        async _deleteAll(ruta = null, whatShouldLookFor = null, functionHandleSuccess = null) {
            if (ruta && whatShouldLookFor) {
                this.cargando = true;
                try {
                    const { status, body } = await this.$http.delete(ruta, this.$headers)
                    this._handleResponse(status, whatShouldLookFor, body, functionHandleSuccess)
                    this.cargando = false;
                } catch (err) {
                    this._handleError(err)
                }
            }
        },
        _handleResponse(responseStatus, whatShouldLookFor, responseBody, functionHandleSuccess) {
            if (
                (responseStatus === 200 || responseStatus === 201) &&
                whatShouldLookFor in responseBody
            ) {
                if (functionHandleSuccess) this[functionHandleSuccess](responseBody);
            } else {
                this.$emit('msg', responseBody.error)
            }
        },
        _handleError(err) {
            this.$emit("httpError", err);
            this.cargando = false;
        },
        _limpiarCampos(propMustBeCleaned = null) {
            if (propMustBeCleaned)
                Object.keys(this[propMustBeCleaned]).forEach(e => {
                    this[propMustBeCleaned][e] = null
                });
            Object.keys(this.errors).forEach(e => {
                this.errors[e] = null
            });

        }
    }
}